import * as React from 'react';
import { db, signOut, signOutOfGoogle, auth } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import {useState} from 'react';
import logo from "./logo-color-2-2.png"
import { getMessage } from './message';
export function Comment() {


  const [address, setAddress] = useState('');
  const [name, setName] = useState('')
  const [sentAddress, setSentAddress] = useState('');
  const [sentName, setSentName] = useState('')
  const [disabled, setDisabled] = useState(true)

  const enabledClasses = "bg-green-600 text-white py-2 px-5 rounded-md"
  const disabledClasses = "bg-green-100 text-white py-2 px-5 rounded-md"

  const handleChange = event => {
    setAddress(event.target.value);
    setDisabled(validate())
  };
  const handleNameChange = event => {
    setName(event.target.value);
    setDisabled(validate())
  };

  function validate() {
    function validateEmail() {
      if (!address) {
        return false
      }
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let add = address.match(validRegex)
      if (add) {
        return add[0].length > 5
      }
      return false
    }
    function validateName() {
      if (!name) {
        return false
      }
      return name.includes(' ') && name.length > 5
    }
    // setting the disabled flag, invert
    let enabled = validateEmail() && validateName()
    return !enabled
  }

  function signOut2() {
    signOutOfGoogle()
    window.location.reload();
  }

  async function sendemail() {
    console.log("send email")
    const user = auth.currentUser;

    if (user) {
      try {
        console.log("db: ", db)
        const docRef = await addDoc(collection(db, "commentCardRequests"), {
          name: name,
          to: [address],
          delivery: [],
          from: "gm@roughcreek.com",
          timestamp: new Date(),
          message: getMessage(name)
        });
        setSentAddress(address)
        setSentName(name)
        setDisabled(true)
      } catch(error) {
        alert(error)
      }
      setName('')
      setAddress('')
    } else {
      alert("No user signed in.")
    }
  }

  return (
    <div>

    <div className="relative">
        <div className="absolute top-0 right-0 h-16 w-32 ">
          <button
          onClick={signOut2}
          className="bg-red-400  py-2 px-5 rounded-md">
          Log Out</button>
        </div>
      </div>

    <div className="md:flex ">
      <div>
        <label className="pt-8  block text-gray-500 font-bold md:text-left ">
        Guest Email Address<br />
        <input
          type="text"
          id="address"
          name="address"
          onChange={handleChange}
          value={address}
          size="800px"
          placeholder="john@smith.com"
          className="bg-gray-200 w-72 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          />
        </label>
      </div>

    </div>
    <div className="md:flex py-8">
      <div>
        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">
        Guest Name including salutation<br />
        <input
          type="text"
          id="name"
          name="name"
          onChange={handleNameChange}
          value={name}
          placeholder="Mr. Smith"
          className="bg-gray-200 w-72 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
        />
        </label>
      </div>
      </div>
      <div className="md:flex ">
        <button
          onClick={sendemail}
          className= {disabled ? disabledClasses : enabledClasses}
          disabled={disabled}
        >
          Send
        </button>
      </div>

      {sentName != '' ?
      <div role="alert" className="pt-20">
        <div className="bg-blue-500 text-white font-bold rounded-t px-4 py-2">
          Message Sent
        </div>
        <div className="border border-t-0 border-red-400 rounded-b bg-blue-100 px-4 py-3 text-blue-700">
          <p>Sent comment card request to: <br/>
          <span className="text-green-600 text-xl">{sentName}</span><br />
          at the address: <br />
          <span className="text-green-600 text-xl">{sentAddress}</span><br />
          </p>
        </div>
      </div>
      : <div> </div> }
    </div>
  )
}
