import * as React from 'react';
import {useState} from 'react';
import logo from "./logo-color-2-2.png"
import { googleProvider, auth, signInWithPopup, signInWithRedirect, onAuthStateChanged, signOutOfGoogle } from './firebase';
import { Comment } from './comment';
import { useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithEmailLink } from "firebase/auth";

export function Login(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user, setUser] = useState('')
  const auth = getAuth();

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const signInWithGoogle = async () => {
    if (user === '') {
      try {
        // const res = await signInWithPopup(auth, googleProvider);
        const res = await signInWithEmailAndPassword(auth, email, password)
        console.log("User returned: ", res.user.email)
        setUser(res.user.email)
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
    } else {
        console.log("Already logged in")
    }
  };

  return (
    <div className="w-full px-20 pt-10">

       <img src={logo} />
      <h1 className="w-full pt-5 text-left text-2xl">Comment Card System</h1>
      { user != '' ?
        <Comment />
      :
      <div>
        <div className="md:flex pt-10">
          <div className="md:flex ">
            <div>
              <label className="pt-8  block text-gray-500 font-bold md:text-left ">
              Your Email Address<br />
              <input
                type="text"
                id="address"
                name="address"
                onChange={handleEmailChange}
                size="800px"
                value={email}
                className="bg-gray-200 w-72 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                />

              </label>
            </div>
          </div>
        </div>

       <div className="md:flex py-8">
          <div>
            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">
            Your Password for Comment Cards<br />
            <input
              type="password"
              id="password"
              name="password"
              onChange={handlePasswordChange}
              value={password}
              className="bg-gray-200 w-72 appearance-none border-2 border-gray-200 rounded text-gray-600 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            />
            <div className="text-xs text-gray-400">This may not be the same as your email password.</div>
            </label>
          </div>
        </div>

      <div className="md:flex py-8">
      <button
          onClick={signInWithGoogle}
          className="bg-green-400  py-2 px-5 rounded-md">
          Log In
        </button>
      </div>
      </div>
  }
</div>
);
}
