import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider, signOut, signInWithPopup, signInWithRedirect, getAuth, onAuthStateChanged, signInWithEmailLink } from "firebase/auth";

const firebase = require("firebase/app");

const firebaseConfig = {
    apiKey: "AIzaSyBIiVmtJJv58QZZ0FjsFn5Kuea6qa6_0SE",
    authDomain: "rclcalls.firebaseapp.com",
    projectId: "rclcalls",
    storageBucket: "rclcalls.appspot.com",
    messagingSenderId: "1008000129220",
    appId: "1:1008000129220:web:f067470d05cfae01339191",
    measurementId: "G-RDENXFJXKE"

};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
const email = "larry@roughcreek.com"
const password = "sheldon"

const googleProvider = new GoogleAuthProvider();

function signOutOfGoogle() {
  console.log("Signing out")
  signOut(auth)
  console.log("Signed out.")
}

export { db, firebase, app, auth, googleProvider, signOut, signInWithPopup, signInWithRedirect, onAuthStateChanged, signOutOfGoogle, signInWithEmailLink };

