export function getMessage(name) {
  const header = `Dear ${name}:`
  const msgTxt = `
  ${header}\n\n

  We believe you are a relevant and credible expert to offer advice on how to help Rough Creek's customer
  service continue to improve.  And as the General Manager, I am sincerely interested in hearing from you
  on the following two questions:
  \n\n

  1) On a scale of 1 through 10, would you recommend Rough Creek Lodge and Resort to your friends,
  family or business associates? What is the single reason for your answer?
  \n\n

  2) Is there any aspect of your stay, no matter how trivial, that you would recommend that we improve upon?
  \n\n

  We welcome any feedback that you may be willing to provide.  We greatly appreciate your opinion.
  Thank you again for staying with us at Rough Creek Lodge and Resort and we hope to see you again soon!
  \n\n


  Sincerely,
  \n\n


  Paul Boccafogli
  General Manager
  `

  const msgHTML = `
  ${header}
  <p>
  We believe you are a relevant and credible expert to offer advice on how to help Rough Creek's customer
  service continue to improve.  And as the General Manager, I am sincerely interested in hearing from you
  on the following two questions:
  </p>

  <p>
  1) On a scale of 1 through 10, would you recommend Rough Creek Lodge and Resort to your friends,
  family or business associates? What is the single reason for your answer?
  </p>

  <p>
  2) Is there any aspect of your stay, no matter how trivial, that you would recommend that we improve upon?
  </p>

  <p>
  We welcome any feedback that you may be willing to provide.  We greatly appreciate your opinion.
  Thank you again for staying with us at Rough Creek Lodge and Resort and we hope to see you again soon!
  </p>

  <p>
  Sincerely,
  </p>

  <p>
  Paul Boccafogli <br />
  General Manager
  </p>
  `

  return {
    subject: 'I appreciate your feedback',
    text: msgTxt,
    html: msgHTML
  }
}
